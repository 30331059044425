import './App.css'
import Selfie from './images/pics/selfie.jpg'; 

function App() {
  return (
		<div className = "container-fluid bg-dark p-3 text-white">
		{/*row 1 #logo, #navigation bar*/}
			<div className = "row pt-2">	
				<div className = "col-md-6"> {/*logo*/}
					<h1 className = "profile-name font-weight-bolder"> <b>PANASHE TICHIWANGANA </b></h1>
				</div>
				<div className = "col-md-6"> {/*navigation bar*/}
				</div>
			</div>
			
		{/*row 2 #introduction, json object*/}
			<div className = "row mt-4 p-2">	
				<div className = "col-md-6">
					<div className = "card bg-dark">
						<div className = "card-header text-white border border-info"> {/*three dots*/}
							<span className = "dots"> . </span> 
							<span className = "dots"> . </span> 
							<span className = "dots"> . </span> 
						</div>
						<div className = "card-body border border-info"> {/* json object*/}
							<code className = "text-white">
									<span className = "text-danger">{"const "}</span> {"coder "} <span className = "text-danger">{"= "} </span> {" {"}<br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"name : "} <span className = "text-warning">{"'Panashe Tichiwangana' ,"}</span><br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"skills: "}<span className = "text-warning">{"['HTML5','CSS3','Javascript','PHP',"} </span><br/>
 &nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp;<span className = "text-warning">{"'React','NextJs','Redux',"} </span><br/>
&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;<span className = "text-warning">{"'Laravel','SQL','MySQL',] ,"} </span><br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"hardWorker: "}<span className = "text-success">{"true ,"} </span><br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"quickLearner:"}<span className = "text-success">{"true ,"} </span><br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"problemSolver:"}<span className = "text-success">{"true ,"} </span><br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"hireable: "}<span className = "text-primary">{"function ()"} </span>{"{"} <br/>
	&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<span className = "text-primary">{"return ("} </span><br/>
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "text-danger">{"this."}</span>{"hardWorker&&"} <br/>
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "text-danger">{"this."}</span>{"problemSolver&&"} <br/>
&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className = "text-danger">{"this."}</span>{"skills.length >= 5"} <br/>
	&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;{")"} <br/>
		   &nbsp; &nbsp;&nbsp;&nbsp;{"}"} <br/>
								{"}"}
							</code>
						</div>
					</div>
				</div>
				<div className = "col-md-6"> {/* contact me*/}
					<p className = "text-white display-4">
						<b>
							Hello,<br/>
							This is <span className = "text-danger"> PANASHE </span>, <br/> 
							I'm a Professional 
							<span className  = "text-success"> Software Developer.</span>
						</b>
					</p>
					<div className = "row mt-4 p-4">
						<div className = "col">
							<button className = "p-3 m-2 rounded btn btn-outline-warning btn-lg"> CONTACT ME </button>
							<button className = "p-3 m-2 rounded btn btn-danger btn-lg"> GET RESUME </button>
						</div>
					</div>
				</div>
			</div>
			
			{/*row 3 #WHO AM I, selfie*/}
			<div className = "row m-2 p-2">	
				<div className = "col m-2 p-2">
					<hr className = "text-secondary"/>
					<div className = "text-center border border-secondary rounded p-2 sub-heading mx-auto">
						<h2 className = "text-center text-dark"> WHO AM I </h2>
					</div>
				</div>
			</div>
			<div className = "row">	
				<div className = "col-md-6 p-2"> {/* who am i*/}
					<p> 
						{"Web developer with a proven ability to adapt in both self-starting and "}
						{"collaborative environments while staying focused on achieving high quality "}
						{"results under strict deadlines."}<br/>
						{"Having Started computers in high school,"} 
						{"I believe I have gained a lot experience in working with computers. "}
						{"Panashe have created a number of projects using different programming languages."}
					</p>
				</div>
				<div className = "col-md-6 text-center"> {/* who am i*/}
					<img className =  "rounded img-fluid mx-auto" src={Selfie} alt="Logo" />;
				</div>
			</div>
			
		</div>
  );
}

export default App;
